import { Accessor, Show } from "solid-js";
import { CoinsTransactionsModal } from "~/components/coins_transactions_modal";
import { useModal } from "~/components/modal";
import { Header } from "~/types";
import { getCookie } from "~/utils/client_cookie";
import { getPlutoReceiverSessionIdStorageKey } from "./pluto-receiver-landing/pluto_reveal_route_data";
import { APIError } from "~/utils/fetch";
import { toIndianNumber } from "~/utils/number";
import { coinIcon } from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { goBack } from "~/shared_states/modal";

export function buildBgGradient() {
  return (
    <div
      class="absolute top-0 h-[134px] w-full"
      style={{
        opacity: 0.25,
        background: "linear-gradient(90deg, #858FFF 0%, #ECA477 100%)",
        filter: "blur(67px)",
      }}
    ></div>
  );
}

export function PlutoBalancePill(props: {
  coinsAvailable: number;
  name: string;
  setRefreshCoins: () => void;
  large: boolean;
  giftingKey: string;
  glow?: Accessor<boolean>;
}) {
  const { openModal, setThemeMode } = useModal()!;

  let glowStyle = {
    border: "1px solid var(--Base-Secondary-Light, #ABABAB)",
    background: "#2A1E05",
    "box-shadow": "0px 0px 30px 0px rgba(255, 191, 66, 0.30)",
  };

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        setThemeMode("dark");

        openModal(() => {
          return (
            <CoinsTransactionsModal
              setRefreshCoins={props.setRefreshCoins}
              balance={props.coinsAvailable}
              userName={props.name}
              enableRedeemCode={false}
              showExpiresOn={false}
              title="Your gift balance"
              buildSubtitle={() => {
                return (
                  <div class="px-4 pt-3 text-center text-medium text-textDark dark:text-textNormal lg:text-normal">
                    {
                      "Use this balance to get gift card(s) of your preferred brand & buy the product you want with the gift card"
                    }
                  </div>
                );
              }}
              headers={{
                [Header.SessionId]:
                  getCookie(
                    getPlutoReceiverSessionIdStorageKey(props.giftingKey)
                  ) ?? "",
              }}
              onError={(error) => {
                if (error instanceof APIError) {
                  if (error.code === 401) {
                    window.location.href = "pluto/reveal/" + props.giftingKey;
                  }
                }
              }}
            />
          );
        });
      }}
      class="flex cursor-pointer flex-row  items-center rounded-[50px] border border-basePrimaryMedium bg-baseDark text-mediumBold transition-all  duration-300 "
      style={
        props.glow?.() ?? false
          ? glowStyle
          : {
              "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
            }
      }
      classList={{
        "p-[6px]  pr-3": props.large,
        "px-[2px] pr-2": !props.large,
        "h-[36px]": props.large,
        "h-[28px]": !props.large,
      }}
    >
      <img
        src={coinIcon}
        class="pr-[6px]"
        classList={{
          "h-7 w-7": !props.large,
          "h-8 w-8": props.large,
        }}
      />
      <Show when={props.large}>Balance: </Show>
      {toIndianNumber(props.coinsAvailable)}
    </div>
  );
}

export function BackButtonReceiver() {
  return (
    <div class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-2">
      <PhosphorIcon
        name="arrow-left"
        fontSize={20}
        class="text-white"
        onClick={() => goBack()}
      />
    </div>
  );
}

export function PlutoCloseButton() {
  return (
    <div class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-2">
      <PhosphorIcon name="x" fontSize={16} class="text-white" />
    </div>
  );
}
